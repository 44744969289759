
import { Component, Mixins } from 'vue-property-decorator';
import utilMixins from '@/mixins';
import vuex_data from '@/vuex/vuex_data';
import * as util from '@/util';

@Component({
  components: {
  }
})
export default class Main extends Mixins(utilMixins) {
  get url() {
    return vuex_data.pdfviewer.data.pdf;
  }

  get is_app() {
    const app_token = util.getLocalStorage('app_token');
    return app_token == null ? 0 : 1;
  }
}


